import { message } from 'antd';
import store from '../store';
import userLogout from '../ducks/logout';

const handleResponseError = (error) => {
  // The request was made and the server responded with a status code
  if (error.response) {
    // 400 Bad Request - The request was unacceptable, usually due to a missing or malformed parameter.
    if (error.response.status === 400) {
      message.error(`Bad request.`);
    } else if (error.response.status === 401) {
      // 401 Unauthorized - The API token was incorrect or missing.
      store.dispatch(userLogout());
    } else if (error.response.status === 403) {
      // 403 Forbidden - The user doesn’t have permission to make this request.
      message.error(`You don't have access to this page.`);
    } else if (error.response.status === 429) {
      // 429 Too Many Requests - Too many requests have been made in a given time period. This only applies to unauthenticated requests.
      message.error('Too many requests. Please try again later.');
    } else if (error.response.status === 404) {
      // 404 Not Found - The resource requested doesn’t exist.
      message.error('Page not found.');
    } else if (error.response.status === 405) {
      // 405 Method Not Allowed
      message.error('Method Not Allowed.');
    } else if (error.response.status === 422) {
      // 422 Unprocessable Entity
      const errorMessage =
        error.response.data?.detail || 'Unable to process this request.';

      message.error(`Status code: 422, ${errorMessage}`);
    } else if (error.response.status >= 500 && error.response.status < 600) {
      // 500 Internal Server Error  Something went wrong on the Zorro API side.
      message.error('Woops, Something went wrong, please try again later.');
    } else {
      // Any other error code
      message.error('An unknown error occurred, please try again later.');
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    message.error('Network error, please try again later.');
  } else {
    // Something happened in setting up the request that triggered an Error
    message.error('An unknown error occurred, please try again later.');
  }

  return Promise.reject(error);
};

export default handleResponseError;
